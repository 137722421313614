import React, { useState, useRef, useEffect } from "react";

import BotText from "./BotText";
import UserText from "./UserText";

const pierwszaWiadomoscSzeptacza = "Witaj, jestem Szeptacz. Naciśnij mikrofon aby rozpocząć rozmowę lub napisz do mnie na czacie.";

export default function DisplayChat(props) {
  const scrollToElement = () => {
    var elem = document.getElementById("chatWindow");
    // console.log(elem.scrollTop);
    elem.scrollTop = elem.scrollHeight;
  };

  useEffect(() => {
    //console.log("useEffect[] - DisplayChat " + mounting);
  }, []);

  useEffect(() => {
    // console.log("DisplayChat useEffect[props]");
    // console.log(props);
    scrollToElement();
  }, [props]);

  return (
    <div
      style={{
        height: "400px",
        //position: "absolute",
        // top: "0",
        // bottom: "200",
        //  right: "0",
        // left: "0",
        //overflow: "hidden",
        // alignItems: "stretch",
        // height: "inherit",
      }}
    >
      <div className="chatWindowContainer h-100">
        <div className="chatWindow p-3" id="chatWindow">
          <div className="scrolldown">
            <div className="dummy_message">
              <BotText text={pierwszaWiadomoscSzeptacza}></BotText>
            </div>
            {props.conversationMessages.map((oneMessage) => {
              if (oneMessage.name == "user") {
                return <UserText text={oneMessage.message}></UserText>;
              }
              if (oneMessage.name == "szeptaczBot") {
                return <BotText text={oneMessage.message}></BotText>;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
