import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { MDBCardImage } from "mdb-react-ui-kit";

import { ConnectionState } from "./components/ConnectionState";
import { ConnectionManager } from "./components/ConnectionManager";
import { MyForm } from "./components/MyForm";
import { Events } from "./components/Events";
import { socket } from "./socket";
import AudioRecorder from "./AudioRecorder";
//import { mediaStream } from "./AudioRecorder";

function MicVideo({ isActive }) {
  var soundWaveMoviePath = "./movies/sound_wave3green.mp4";

  if (!isActive) {
    //soundWaveMoviePath = "./movies/sound_wave2green.mp4";
    return null;
  }
  return (
    <div
      className="p-3"
      style={{
        gridArea: "1/1",
        zIndex: "1",
      }}
    >
      <video
        className="w-100"
        autoPlay
        loop
        muted
        style={{ borderRadius: "30px" }}
      >
        <source src={soundWaveMoviePath} type="video/mp4" allowFullScreen />
      </video>
    </div>
  );
}

export default function Microphone(props) {
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  var mountCount = 0;
  var recognitionCounter = 0;

  const audio = new Audio();

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    mountCount++;
    if (mountCount === 1) {
      function onConnect() {
        setIsConnected(true);
      }

      function onDisconnect() {
        setIsConnected(false);
      }

      socket.on("connect", () => {
        console.log("connected");
        onConnect();
        console.log("sending hello..");
        socket.emit("hello", "its me");
      });

      socket.on("disconnect", onDisconnect);

      socket.on("connect_error", (error) => {
        if (socket.active) {
          // temporary failure, the socket will automatically try to reconnect
        } else {
          // the connection was denied by the server
          // in that case, `socket.connect()` must be manually called in order to reconnect
          console.log(error.message);
        }
      });

      socket.on("hello", (arg) => {
        console.log("hello " + arg);
        props.getClientNumber(arg);
      });

      socket.on("response", (text) => {
        console.log("got answer on my question - " + text);
      });

      socket.on("client_question", (response) => {
        // got recognized client question
        console.log("on client_question");
        response = JSON.parse(response);
        console.log(response);
        props.postClientQuestion(response);
      });

      socket.on("client_response", (response) => {
        console.log("got client_response");
        response = JSON.parse(response);
        //document.getElementById("input_question").value = response.question;
        //document.getElementById("input_response").value = response.answer_text;

        // props.getMessage({
        //   name: "user",
        //   message: response.question,
        // });
        // props.getMessage({
        //   name: "szeptaczBot",
        //   message: response.answer_text,
        // });
        props.postBotResponseText(response.answer_text);
        console.log("response.question = " + response.question);
        console.log("response.answer_text = " + response.answer_text);
        if (response.answer_audio.length > 0) {
          console.log("playing audio answer");

          audioPlay(response.answer_audio);
        }
      });

      socket.on("audio_data", (audioData) => {
        console.log("received audio data");
        audioPlay(audioData);
      });

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
      };
    }
  }, []);

  useEffect(() => {
    console.log(
      "useEffect Microphone props.clickedButton.topic+" +
        props.clickedButton.topic
    );
    if (props.clickedButton.index != 0)
      socket.emit("client_whisper", props.clickedButton.topic);
  }, [props.clickedButton]);

  useEffect(() => {
    if (props.client_chat != "") {
      console.log("sending client chat: " + props.client_chat);
      socket.emit("client_chat", props.client_chat);
      // var emulatedResponse = {
      //   question: props.client_chat,
      //   is_final: true
      // };
      // console.log("adding chat question");
      // props.postClientQuestion(emulatedResponse);
    }
  }, [props.client_chat]);

  async function staticAudioPLay() {
    console.log("fetching static audio file");
    const response = await fetch("/audio/audio-01.wav");
    const blob = await response.blob();
    const audioUrl = window.URL.createObjectURL(blob);
    const audio = new Audio(audioUrl);
    audio.play();
  }

  function audioPlay(data) {
    //console.log("audioPlay.....");

    const blob = new Blob([data], { type: "audio/wav" });
    const audioUrl = window.URL.createObjectURL(blob);
    //const audio = new Audio(audioUrl);
    //audio.stop();
    audio.src = audioUrl;
    audio.play();
  }

  const startRecording = async () => {
    console.log("startRecording");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { sampleSize: 16, channelCount: 1, sampleRate: 16000 },
        //audio: true,
      });
      console.log("nowy stream");

      mediaStream.current = stream;

      mediaRecorder.current = new MediaRecorder(mediaStream.current, {
        mimeType: "audio/webm; codecs=opus",
      });

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          socket.emit("start_audio"); // naprawa dziwanczna problemu z start recognition po ponowny włączeniu mikrofonu
          socket.emit("audio_chunk", e.data);
          console.log("emituje jak gupi + isMicActive=" + isMicActive);
        }
      };
      mediaRecorder.current.onstop = () => {
        console.log("Recorder stop");
        stopRecording();
      };
      mediaRecorder.current.onerror = (e) => {
        console.error("Recorder error", e);
        stopRecording();
      };
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }

    mediaRecorder.current.start(50);

    recognizeStart();
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      console.log("pauseRecording");
    } else {
      console.log("notRecording");
    }
  };

  const stopRecording = () => {
    console.log("stopRecording");

    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      console.log("q1");
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
        console.log("q2");
      });
    }

    recognizeStop();
  };

  function recognizeStop() {
    console.log("recognizeStop");
    socket.emit("stop_audio");

    // recognitionCounter = 0;

    //stopRecording();
  }

  function recognizeStart() {
    console.log("recognizeStart");

    socket.emit("start_audio");
    // recognitionCounter++;
  }

  function SocketDisconnect() {
    console.log("SocketDisconnect");

    socket.disconnect();
    setIsConnected(false);
  }
  function SocketConnect() {
    console.log("SocketConnect");

    setIsConnected(true);
    socket.connect();
  }
  const UserSendsText = () => {
    var text = "O rany skąd ten text wziąć";
    socket.emit("question", text);
  };
  const PlayAudioFromWebSocket = () => {
    console.log("requesting for audio to play");
    socket.emit("request_audio");
  };
  const PlayStaticAudio = () => {
    staticAudioPLay();
  };

  ////////////////////////////////////////////////////////
  const buttonActivePath = "./images/microphone_trans2_activ.png";
  const buttonNotActivePath = "./images/microphone_trans2.png";

  const [isMicActive, setIsMicActive] = useState(false);
  const [buttonTogglePath, setPath] = useState(buttonNotActivePath);

  const toggleMicActive = () => {
    console.log("mic kliknięty był - " + isMicActive + " " + buttonTogglePath);

    if (isMicActive) {
      setIsMicActive(false);
    } else {
      setIsMicActive(true);
    }
    console.log(
      "mic kliknięty teraz jest - " + isMicActive + " " + buttonTogglePath
    );
  };

  useEffect(() => {
    console.log("useEffect[isMicActive] + isMicActive=" + isMicActive);

    if (isMicActive) {
      setPath(buttonActivePath);
      StartMic();
    } else {
      setPath(buttonNotActivePath);
      StopMic();
    }
  }, [isMicActive]);

  const StartMic = () => {
    console.log("start mic");
    startRecording();

    //if (mediaRecorder.current === null) startRecording();

    // if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
    //   console.log("resume Recording");
    //   mediaRecorder.current.resume();
    // }

    /// recognizeStart();
  };
  const StopMic = () => {
    console.log("stop mic");
    // if (recorder) {
    //   recorder.stop();
    // }
    // stopRecording();
    // SocketDisconnect();

    // recognizeStop();
    // recognizeStart();

    stopRecording();

    //pauseRecording();
  };

  return (
    <div>
      <div
        // className="row py-0"
        className={isMicActive === true ? "pulse row py-0" : "row py-0"}
        style={{
          display: "grid",
          // height: "150px",
        }}
      >
        {/* <MicVideo isActive={isMicActive}></MicVideo> */}
        {/* <div className={isMicActive === true ? "gelatine" : ""}> */}
        <Button
          style={{
            background: "rgba(60,60,60,0)",
            border: "none",
            gridArea: "1/1",
            zIndex: "2",
          }}
          variant="dark"
          onClick={toggleMicActive}
        >
          <MDBCardImage src={buttonTogglePath} alt="..." />
        </Button>
        {/* </div> */}
      </div>
    </div>
  );
}
